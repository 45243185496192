import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export function Seo ({ description, lang, meta, title }) {
  const data = useStaticQuery(graphql`
    query SeoQuery {
      site: settingsJson(
        fileRelativePath: { eq: "/content/settings/site.json" }
      ) {
        title
        description
      }
    }
  `)

  const site = data.site
  const metaDescription = description || site.description

  return (
    <Helmet
      htmlAttributes={{ lang }} title={title} titleTemplate={`%s | ${site.title}`} meta={[{
        name: 'description',
        content: metaDescription
      }, {
        property: 'og:title',
        content: title
      }, {
        property: 'og:description',
        content: metaDescription
      }, {
        property: 'og:type',
        content: 'website'
      }, {
        name: 'twitter:card',
        content: 'summary'
      }, {
        name: 'twitter:creator',
        content: site.author
      }, {
        name: 'twitter:title',
        content: title
      }, {
        name: 'twitter:description',
        content: metaDescription
      }].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}
